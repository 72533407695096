import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ActionButton from '../ActionButton/ActionButton';

const MessagePreview = ({ askIfDelete, message }) => {
    let history = useHistory();
    const nextPath = (path) => {
        history.push(path);
    };

    return (
        <>
            <article className='each-post-prev'>
                <div className='info'>
                    <div className="title-btn">
                        <h5>{ message.subject }</h5>
                        <div className='btn-group'>
                            <ActionButton type={ 'delete' } action={ () => askIfDelete(message._id) } />
                            <ActionButton type={ 'see' } action={ () => nextPath(`/bratic/mensajes/ver/${message._id}`) } />
                        </div>

                    </div>
                    <p>Fecha de recepción: <strong>{ dayjs(message?.createdAt).format('DD/MM/YYYY HH:mm') }</strong></p>
                </div>
            </article>
        </>
    );
};

export default MessagePreview;
